'use strict';

import { onReady, $document, $window } from '../utilities/_helpers';

function activate(): void {
    if (window.location.hash.length > 1) {
        const $tabs = $('[data-toggle="tab"]');
        $tabs.filter(`[data-target="${window.location.hash}"], [href*="${window.location.hash}"]`).tab('show');
    }
}

$document.on('activateRelevantTab', activate);
activate();

// Bootstrap doesn't properly unset tabs which aren't grouped into a single nav
$document.on('shown.bs.tab', '[data-toggle="tab"]', () => {
    const $panes = $('.tab-pane');
    $panes.not('.active').each((index, el) => {
        $(`[data-target="#${el.id}"], [href*="#${el.id}"]`).removeClass('active').attr('aria-expanded', 'false');
    });
    $window.resize();
});
