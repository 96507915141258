'use strict';

import { onResize, onReady, isBreakpointAndDown, $document } from '../utilities/_helpers';

let $steps = $('[data-js="progress-steps"]'),
    isResizeEventAttached = false;

function adjust(): void {
    $steps = $('[data-js="progress-steps"]');
    const isMobile = isBreakpointAndDown('md');

    $steps.each((index, el) => {
        const $el = $(el);

        $el.css({'margin-top': isMobile ? ($el.find('[data-js="progress-step-active"]').outerHeight() + 'px') : 0});
    });
}

if (!!$steps.length) {
    onReady(adjust);
    onResize(adjust);
    isResizeEventAttached = true;
} else {
    $document.on('initializeProgressSteps', function() {
        adjust();
        if (!isResizeEventAttached) {
            onResize(adjust);
        }
    });
}
