'use strict';

import {$document} from '../utilities/_helpers'

interface ValidationRule {
    type: string;
    method: Function
}


const validationRules: ValidationRule[] = [
    {
        'type': 'upper-case-character',
        'method': hasUpperCaseCharacter
    },
    {
        'type': 'lower-case-character',
        'method': hasLowerCaseCharacter
    },
    {
        'type': 'numeric-character',
        'method': hasNumber
    }
];

function hasUpperCaseCharacter(str: string): boolean {
    const pattern = /[A-Z]/;
    return pattern.test(str);
}

function hasLowerCaseCharacter(str: string): boolean {
    const pattern = /[a-z]/;
    return pattern.test(str);
}

function hasNumber(str: string): boolean {
    const pattern = /[0-9]/;
    return pattern.test(str);
}

$document.on('input', '[data-validate-password-conditions]', function() {

    const $this: JQuery = $(this),
        $input: String = $this.val(),
        $conditions: JQuery = $('#' + $this.data('validate-password-conditions')).children();

    $conditions.each(function() {
        const $condition = $(this);
        $.each(validationRules, function(index, validationRule) {
            if ($condition.hasClass(validationRule.type)) {
                if (validationRule.method($input)) {
                    $condition.addClass('is-valid-check');
                } else {
                    $condition.removeClass('is-valid-check');
                }
            }
        })
    });

    if ($conditions.length === $conditions.filter('.is-valid-check').length) {
        $this.closest('.form-group').addClass('is-valid-check');
    } else {
        $this.closest('.form-group').removeClass('is-valid-check');
    }
});
