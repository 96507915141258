'use strict';

import {$document} from '../utilities/_helpers'

$document.on('change', 'input[type="radio"][name="account-poa-has-same-address"]', function() {
    const isDiffAddress = $('input[type="radio"][name="account-poa-has-same-address"]').filter(':checked').val() === 'yes',
          $differentMailingAddress = $('.my-profile-pov-address-row');

    if (isDiffAddress) {
        $differentMailingAddress.removeClass('is-visible');
    } else {
        $differentMailingAddress.addClass('is-visible');
    }
});
