'use strict';

import { $document } from '../utilities/_helpers';

function showAllProviderLocations($this: JQuery) {
    $this.addClass('all-locations-visible');
    $this.text($this.data('hide-text'));
    $('.aon-provider-location', $($this.parents('.aon-provider-result')[0])).fadeIn();
}

function hideOtherLocations($this: JQuery) {
    $this.removeClass('all-locations-visible');
    $this.text($this.data('show-text'));

    const $locations = $('.aon-provider-location', $($this.parents('.aon-provider-result')[0])),
          $checkedLocation = $('input', $locations).filter(':checked');

    if ($checkedLocation.length > 0) {
        $locations.not($checkedLocation.parents('.aon-provider-location')).fadeOut();
    } else {
        $locations.not(':first').fadeOut();
    }
}

$document.on('click', '.view-all-locations-link', function() {
    const shouldShow = !($(this).hasClass('all-locations-visible'))
    if (shouldShow) {
        showAllProviderLocations($(this));
    } else {
        hideOtherLocations($(this));
    }
});

function providerResultsAdded() {
    const viewLocationsLinks = $('.view-all-locations-link');
    viewLocationsLinks.not('.initialized').each(function(i, e){
        const $locations = $('.aon-provider-location', $($(e).parents('.aon-provider-result')[0])),
              $checkedinput = $('input[type="radio"]', $locations).filter(':checked');

        if ($checkedinput.length > 0) {
            const $checkedLocation = $checkedinput.parents('.aon-provider-location').first();
            $locations.not($checkedLocation).hide();
        } else {
            $locations.not(':first').hide();
        }

        viewLocationsLinks.addClass('initialized');
    });
}

$document.on('providerResultsAdded', providerResultsAdded);
