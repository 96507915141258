'use strict';

import { focus, onScrollStop, onLoad, $window, $document, $main } from '../utilities/_helpers';

let scroll = 0;

$window.on('hashchange', () => {
    const hash = window.location.hash;
    let $el = $main.find(hash);

    if (!hash || !hash.slice(1) || !$el.length) {
        $el = $main;
    }

    $window.scrollTop(scroll);

    $el.velocity('scroll', {
        complete: () => {
            scroll = $window.scrollTop();

            focus($el);
        }
    });
});

$document.on('click', '[href*="#"]', () => {
    scroll = $window.scrollTop();
});

if (window.location.hash.length > 1) {
    onScrollStop(() => {
        scroll = $window.scrollTop();
    });

    onLoad(() => {
        scroll = $window.scrollTop();

        $window.trigger('hashchange');
    });
}
