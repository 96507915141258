'use strict';

import { hooks } from 'prismjs';
import 'prismjs/plugins/unescaped-markup/prism-unescaped-markup';
import { html } from 'js-beautify';
import * as Clipboard from 'clipboard';

import { prefix, onReady, onResize, $html } from '../utilities/_helpers';

const $guide = $(`.${prefix('page-style-guide')}`);
const $nav = $guide.find('[data-example-nav]');
const $examples = $guide.find('[data-example]');

function adjust(): void {
    $html.css({'margin-bottom': $nav.outerHeight()});
}

if (!!$guide.length) {
    $guide
        .on('mouseenter', '[data-example]', (evt: Event) => {
            $examples
                .removeClass(prefix('example-highlight'))
                .filter(`[data-example="${$(evt.target).closest('[data-example]').data('example')}"]`)
                .addClass(prefix('example-highlight'));
        })
        .on('mouseleave', '[data-example]', () => {
            $examples.removeClass(prefix('example-highlight'));
        });

    new Clipboard(`.${prefix('example-copy')}`, {
        text: (trigger) => $(`[data-example="${$(trigger).data('example')}"] code`).data('clipboard')
    });

    hooks.add('before-sanity-check', (env) => {
        if (env.language === 'markup') {
            env.element.textContent = env.code = html(env.code, {
                wrap_line_length: 0,
                unformatted: 'none'
            });

            $(env.element).data('clipboard', env.code);
        }
    });

    onResize(adjust);

    onReady(adjust);
}
