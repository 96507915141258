'use strict';

import {$document} from '../utilities/_helpers'

$document.on('input', '[data-repass-for], [data-re-entering-for]', function() {
    const $this: JQuery = $(this),
        $passwordField: JQuery = $this.data('repass-for') ? $('#' + $this.data('repass-for')) : $('#' + $this.data('re-entering-for')),
        passValue = $passwordField.val(),
        repassValue = $this.val();

    if (passValue === repassValue && passValue !== '') {
        $this.closest('.form-group').addClass('is-valid-check');
    } else {
        $this.closest('.form-group').removeClass('is-valid-check');
    }
});

$document.on('input', 'input[id]', function() {
    const $this = $(this);
    if ($.trim($this.prop('id')) !== '') {
        const $repassField: JQuery = $('[data-repass-for="' + $this.prop('id') + '"], [data-re-entering-for="' + $this.prop('id') + '"]');
        if ($repassField.length > 0) {
            const passValue = $this.val(),
                    repassValue = $repassField.val();

            if (passValue === repassValue && passValue !== '') {
                $repassField.closest('.form-group').addClass('is-valid-check');
            } else {
                $repassField.closest('.form-group').removeClass('is-valid-check');
            }
        }
    }
});
