'use strict';

import { prefix, focus, onReady, onResize, isBreakpointAndDown, $html, $main, $document } from '../utilities/_helpers';

let $header: JQuery,
    $navUtil: JQuery,
    $toolbar: JQuery,
    $navMain: JQuery,
    $menus: JQuery,
    $subMenus: JQuery,
    $search: JQuery,
    $close: JQuery,
    $account: JQuery,
    $accountMenus: JQuery,
    $accountToggle: JQuery,
    $accountTab: JQuery,
    $userLoggedIn: JQuery,
    $notifications: JQuery,
    active = 0;

function initializeHeaderVariables() {
    $header = $('.aon-header');
    $navUtil = $header.find('[data-js="nav-util"]');
    $toolbar = $navUtil.find('[data-js="nav-util-toolbar"]');
    $navMain = $header.find('[data-js="nav-main"]');
    $menus = $header.find('[data-js^="menu-"]');
    $subMenus = $header.find('[data-js^="sub-menu-"]');
    $search = $menus.filter('[data-js="menu-search"]');
    $close = $header.find('[data-js="menu-close"], [data-js="search-cancel"]');
    $account = $menus.filter('[data-js="menu-account"]');
    $accountMenus = $header.find('[data-js="account-activity"], [data-js="account-menu"]');
    $accountToggle = $navUtil.find('[data-toggle="account-activity"]');
    $accountTab = $navUtil.find('[data-toggle="tab"]');
    $userLoggedIn = $navUtil.find('[data-js="user-logged-in"]');
    $notifications = $navUtil.find('[data-js="user-notifications"]');
    active = 0;
}

function adjust(): void {
    const $active = $menus.filter('.show'),
          adj = {
                top: 0,
                bottom: 0,
                left: 0
            };
    let hat = 0;

    if (isBreakpointAndDown('md')) {
        adj.top += Math.floor($navMain.outerHeight());

        if (!$notifications.hasClass(prefix('hidden'))) {
            adj.top += (hat = Math.floor($notifications.outerHeight()));
        }

        adj.bottom += Math.floor($navUtil.outerHeight());
    } else {
        adj.left += Math.floor($navMain.outerWidth());
    }

    $html.css({
        'margin-top': `${adj.top}px`,
        'margin-bottom': `${adj.bottom}px`,
        'margin-left': `${adj.left}px`
    });

    $navUtil.css({left: `${adj.left}px`});

    $navMain.css({top: `${hat}px`});

    $userLoggedIn.css({width: $toolbar.outerWidth()});

    if (!!$active.length) {
        resizeMenu($active);
    }
}

function closeMenu(): void {
    const $active = $menus.filter('.show');

    if (!!$active.length) {
        $active.collapse('hide');
    }
}

function resizeMenu($menu: JQuery): void {
    if (isBreakpointAndDown('md')) {
        let height = window.innerHeight;

        height -= $navMain.outerHeight();
        height -= $toolbar.outerHeight();

        if (!$userLoggedIn.hasClass(prefix('hidden'))) {
            height -= $userLoggedIn.outerHeight();
        }

        if (!$notifications.hasClass(prefix('hidden'))) {
            height -= $notifications.outerHeight();
        }

        // Bootstrap functionality controls height
        $menu.css({
            width: 'auto',
            'min-height': `${height}px`,
            'max-height': `${height}px`
        });

        $search.css({top: 'auto'});
    } else {
        $menu.css({
            width: $toolbar.outerWidth(),
            'min-height': 0,
            'max-height': 'none'
        });
    }
}

function initializeHeader() {
    initializeHeaderVariables();
    $menus.on({
        'show.bs.collapse': (evt: Event) => {
            const $target = $(evt.target).not($subMenus);

            if (!!$target.length) {
                active++;

                $html.addClass(prefix('header-menu-active'));

                closeMenu();

                resizeMenu($target);
            }
        },
        'shown.bs.collapse': (evt: Event) => {
            const $target = $(evt.target).not($subMenus);

            if (!!$target.length) {
                const $input = $target.find('input');

                focus(!!$input.length ? $input : $target);
            }
        },
        'hidden.bs.collapse': (evt: Event) => {
            const $target = $(evt.target).not($subMenus);

            if (!!$target.length) {
                active--;

                if (!active) {
                    $html.removeClass(prefix('header-menu-active'));
                }
            }
        }
    });

    $subMenus.on('show.bs.collapse', () => $subMenus.filter('.show').collapse('hide'));

    $accountToggle.on('click', () => {
        $account.toggleClass(prefix('bg-red'));

        $accountMenus.toggleClass(prefix('hidden'));
    });

    $accountTab.on('click', (evt: Event) => {
        const $target = $(evt.target),
              target = $target.data('target'),
              $tab = $(target);

        if (!!$tab.length) {
            closeMenu();
        } else {
            window.location.href = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : undefined) + $target.attr('href') + target;
        }
    });

    $close.on('click', closeMenu);

    $main.on('click', $main, closeMenu);

    onResize(() => {
        const $active = $menus.filter('.show');

        if (!!$active.length) {
            resizeMenu($active);
        }

        adjust();
    });

    onReady(adjust);
}

if (!!$('.aon-header').length) {
    initializeHeader()
} else {
    $document.on('initializeHeader', initializeHeader);
}
