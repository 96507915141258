'use strict';

import { $document } from '../utilities/_helpers';

function ActivatePageLevelLoader() {
    $('#page_overlay').show();
}

function DeactivatePageLevelLoader() {
    $('#page_overlay').hide();
}

$(document).on('activatePageLoader', ActivatePageLevelLoader);
$(document).on('deactivatePageLoader', DeactivatePageLevelLoader);
