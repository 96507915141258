'use strict';

import {$document} from '../utilities/_helpers'

$document.on('change', 'input[type="radio"][name="differentMailingAddress"]', function() {
    const isDiffAddress = $('input[type="radio"][name="differentMailingAddress"]').filter(':checked').val() === 'yes',
          $differentMailingAddress = $('.mailing-address-different');

    if (isDiffAddress) {
        $differentMailingAddress.slideDown();
    } else {
        $differentMailingAddress.slideUp();
    }
});

$document.on('change', 'input[type="radio"][name="addPOA"]', function() {
    const isAddingPOA = $('input[type="radio"][name="addPOA"]').filter(':checked').val() === 'yes',
          $addPOADetails = $('.poa-details-container');

    if (isAddingPOA) {
        $addPOADetails.slideDown();
    } else {
        $addPOADetails.slideUp();
    }
});

$document.on('change', 'input[type="radio"][name="POAHasSameAddress"]', function() {
    const isPOADiffHasAddress = $('input[type="radio"][name="POAHasSameAddress"]').filter(':checked').val() === 'no',
          $POAMailingAddress = $('.poa-mailing-address');

    if (isPOADiffHasAddress) {
        $POAMailingAddress.slideDown();
    } else {
        $POAMailingAddress.slideUp();
    }
});
