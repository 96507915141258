'use strict';

import {$document} from '../utilities/_helpers'

let $ssnForm = $('.ssn-form'),
    $exchangeIDForm = $('.exchange-id-form'),
    $existingAccountFormContainer = $('.existing-account-form--container'),
    $commonFormLastName = $('.common-form--last-name'),
    $commonFormDobAndZip = $('.common-form--dob-zip'),
    $createAccountButtonContainer = $('.create-account-button-container'),
    $loginButtonContainer = $('.login-button-container');

function initializeVariables() {
    $ssnForm = $('.ssn-form'),
    $exchangeIDForm = $('.exchange-id-form'),
    $existingAccountFormContainer = $('.existing-account-form--container'),
    $commonFormLastName = $('.common-form--last-name'),
    $commonFormDobAndZip = $('.common-form--dob-zip'),
    $createAccountButtonContainer = $('.create-account-button-container'),
    $loginButtonContainer = $('.login-button-container');
}

function displayFlex($e: JQuery) {
    $e.css('display', 'flex');
}

function enableSSNForm() {
    $exchangeIDForm.hide();
    $existingAccountFormContainer.hide();
    $loginButtonContainer.hide();

    displayFlex($ssnForm);
    displayFlex($commonFormLastName);
    displayFlex($commonFormDobAndZip);
    displayFlex($createAccountButtonContainer);
}

function exchangeIDForm() {
    $ssnForm.hide();
    $existingAccountFormContainer.hide();
    $loginButtonContainer.hide();

    displayFlex($exchangeIDForm);
    displayFlex($commonFormLastName);
    displayFlex($commonFormDobAndZip);
    displayFlex($createAccountButtonContainer);
}

function existingAccountForm() {
    $ssnForm.hide();
    $exchangeIDForm.hide();
    $commonFormLastName.hide();
    $commonFormDobAndZip.hide();
    $createAccountButtonContainer.hide();

    displayFlex($existingAccountFormContainer);
    displayFlex($loginButtonContainer);
}

$document.on('change', '[type="radio"][name="identity"]', function() {
    const selectedOption = $('[type="radio"][name="identity"]').filter(':checked').val();

    initializeVariables();

    switch (selectedOption) {
        case 'ssn': {
            enableSSNForm();
            return;
        }
        case 'exchange_id': {
            exchangeIDForm();
            return;
        }
        case 'existing_account': {
            existingAccountForm();
            return;
        }
    }
});
