'use strict';

/*
 This is a general initialization of Slick.js carousels.
 Documentation: http://kenwheeler.github.io/slick/

 Custom configuration is passed in using the data-carousel attribute. Options are merged with defaults found below.
 */

import { merge } from 'lodash';

import { prefix, onReady, $document } from '../utilities/_helpers';

function initializeCarousel() {
    const $carousels = $('[data-carousel]');
    $carousels.each((index) => {
        const $carousel = $carousels.eq(index),
              $prev = $carousel.find('[data-js="carousel-prev"]').detach(),
              $next = $carousel.find('[data-js="carousel-next"]').detach();

        $carousel
            .on('init', () => {
                $carousel.append($prev, $next);
            })
            .slick(merge(
                {},
                {
                    speed: 300,
                    dots: true,
                    arrows: true,
                    mobileFirst: true,
                    variableWidth: true,
                    slidesToShow: 3,
                    swipeToSlide: true,
                    slide: '[data-js="carousel-slide"]',
                    prevArrow: $prev,
                    nextArrow: $next
                },
                $carousel.data('carousel') || {}
            ));
    })
    .removeAttr('data-carousel');
}

onReady(() => {
    const $carousels = $('[data-carousel]');

    if (!!$carousels.length) {
        initializeCarousel();
    }
});

$document.on('initializeCarousel', initializeCarousel);
