'use strict';

import {$document} from '../utilities/_helpers'

$document.on('change', 'input[type="radio"][name="can-send-physical-mail"]', function() {
    const canSendMail = $('input[type="radio"][name="can-send-physical-mail"]').filter(':checked').val() === 'yes',
          $mailAddressForm = $('.com-pref-can-send-mail-form');

    if (canSendMail) {
        $mailAddressForm.addClass('can-send-email');
    } else {
        $mailAddressForm.removeClass('can-send-email');
    }
});

$document.on('change', 'input[type="radio"][name="can-call"]', function() {
    const canCall = $('input[type="radio"][name="can-call"]').filter(':checked').val() === 'yes',
          $phoneNoForm = $('.com-pref-can-call-form');

    if (canCall) {
        $phoneNoForm.addClass('can-call');
    } else {
        $phoneNoForm.removeClass('can-call');
    }
});
