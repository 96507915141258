'use strict';

import {$document} from '../utilities/_helpers'

function showPassword(): void {
   $document.on('click', '[data-show-password]', function() {

       const showPasswordFor = $(this).data('show-password'),
             $links = $('[data-show-password="' + showPasswordFor + '"]'),
             $input = $('#' + showPasswordFor);
       if ($input.attr('type') === 'text') {
            $input.attr('type', 'password');
            $links.text('Show Password');
        } else {
            $input.attr('type', 'text');
            $links.text('Hide Password');
        }
   });
}

showPassword();
