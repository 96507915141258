'use strict';

/*
 This is functionality for the placeholder label element which swaps state on focus and when the input has a value.
 */

import { prefix } from '../utilities/_helpers';

const $inputs = $(`.${prefix('placeholder-label')} .form-control`); // TODO: Switch to [data]

function toggle(el: Element): void {
    const $input = $(el);

    $input[`${!!$input.val() ? 'add' : 'remove'}Class`](prefix('holding'));
}

if (!!$inputs.length) {
    $inputs
        .on('focus blur keyup change', (evt: Event) => toggle(evt.currentTarget as Element))
        .each((index, el) => toggle(el));
}
