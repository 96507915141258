'use strict';

import { $body } from '../utilities/_helpers';

/* TODO: Add [data-bypass-prevent]
 Prevent default Bootstrap behavior from within by using data-prevent attribute and pass the event to be prevented.
 */

$body.on('click', '[data-prevent]', (evt: Event) => {
    const $target = $(evt.currentTarget);
    const event = $target.data('prevent');
    const $parent = $($target.closest(event.slice(event.lastIndexOf('.')))); // Extra JQuery wrapper due to any[] typed return definition

    $parent.one(event, (e: Event) => e.preventDefault());
});
