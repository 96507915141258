'use strict';

import { $document } from '../utilities/_helpers';

function activateButtonLoader(e: Object, obj: JQuery) {
    const $this = $(obj);
    $this.addClass('disabled show-btn-loader').attr('disabled', 'disabled');
}

function deactivateButtonLoader(e: Object, obj: JQuery) {
    const $this = $(obj);
    $this.removeClass('disabled show-btn-loader').removeAttr('disabled');
}

$document.on('activateButtonLoader', activateButtonLoader);
$document.on('deactivateButtonLoader', deactivateButtonLoader);
