'use strict';

import { merge } from 'lodash';
import {$document} from '../utilities/_helpers'

const defaults = {
    placement: 'bottom'
};

$document.on('initializeTooltips', function() {
    const $tooltips = $('[data-toggle="tooltip"]');
    $tooltips.each((index, tooltip) => {
        const $tooltip = $(tooltip);
        $tooltip.tooltip(merge({}, defaults, $tooltip.data('tooltip')));
    });
})
