'use strict';

import {$document} from '../utilities/_helpers'


$document.on('click', '.use-different-number', function(e) {
    e.preventDefault();

    const $parent = $(this).parents('.aon-appointment-confirmed-box-contents').first(),
            $currentNumberSection = $('.aon-appointment--details', $parent),
            $differntNumberform = $('.aon-use-different-number-form', $parent);

    $currentNumberSection.hide();
    $differntNumberform.show();
});

$document.on('click', '.aon-use-different-number-form .aon-cta', function(e) {
    e.preventDefault();

    const $parent = $(this).parents('.aon-appointment-confirmed-box-contents').first(),
            $currentNumberSection = $('.aon-appointment--details', $parent),
            $differntNumberform = $('.aon-use-different-number-form', $parent);

    $currentNumberSection.show();
    $differntNumberform.hide();
});
