'use strict';

import {$document} from '../utilities/_helpers'

function hideOrShowAlerts() {
    if ($('.hide-my-account-alerts').length > 0) {
        $('.aon-menu-content .alert.aon-alert').hide();
        $('.aon-menu-content+.card-block').hide();
        $('.aon-menu-flag').hide();
        $('.aon-menu-items').show();
        $('.adv-name').show();
    } else {
        $('.aon-menu-content .alert.aon-alert').show();
        $('.aon-menu-content+.card-block').show();
        $('.aon-menu-items').hide();
        $('.aon-menu-flag').show();
    }
}
hideOrShowAlerts();
