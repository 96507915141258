'use strict';

import { bind, call, debounce, forEach, invokeMap } from 'lodash';

import { PREFIX, BREAKPOINTS } from '../_vars';

export function toDashCase(str = ''): string {
    return str.trim().toLowerCase().replace(/[\s_]/g, '-');
}

export function prefix(...strings: string[]): string {
    return strings.map((str) => `${PREFIX}-${toDashCase(str)}`).join(' ');
}

export function parameters(): { [param: string]: any } {
    const params = {};
    const pairs = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

    forEach(pairs, (pair) => {
        const hash = pair.split('=');

        params[hash[0]] = hash[1] ? decodeURIComponent(hash[1]) : true;
    });

    return params;
}

const $breakpoint = $(`#${prefix('breakpoint')}`);

export function getBreakpoint() {
    return $breakpoint.css('font-family');
}

export function isBreakpoint(breakpoint: string): boolean {
    return breakpoint === getBreakpoint();
}

export function isBreakpointAndUp(breakpoint: string): boolean {
    return BREAKPOINTS.indexOf(breakpoint) <= BREAKPOINTS.indexOf(getBreakpoint());
}

export function isBreakpointAndDown(breakpoint: string): boolean {
    return BREAKPOINTS.indexOf(breakpoint) >= BREAKPOINTS.indexOf(getBreakpoint());
}

export function focus($el: JQuery): void {
    $el.trigger('focus');

    if (!$el.is(':focus')) {
        $el
            .attr('tabindex', '-1')
            .trigger('focus')
            .one('blur', () => {
                $el.removeAttr('tabindex');
            });
    }
}

let scrollStopped = true;
const callbacks = {
    load: [] as Function[],
    ready: [] as Function[],
    resize: [] as Function[],
    scroll: {
        start: [] as Function[],
        every: [
            debounce(() => {
                invokeMap(callbacks.scroll.stop, call);

                scrollStopped = true;
            }, 250)
        ] as Function[],
        stop: [] as Function[]
    }
};

export function onLoad(callback: Function) {
    if (!!callbacks.load) {
        callbacks.load.push(callback);
    } else {
        callback();
    }
}

export function onReady(callback: Function) {
    if (!!callbacks.ready) {
        callbacks.ready.push(callback);
    } else {
        callback();
    }
}

export function onResize(callback: Function) {
    callbacks.resize.push(callback);
}

export function onScrollStart(callback: Function) {
    callbacks.scroll.start.push(callback);
}

export function onScroll(callback: Function) {
    callbacks.scroll.every.push(callback);
}

export function onScrollStop(callback: Function) {
    callbacks.scroll.stop.push(callback);
}

export const $window = $(window);

$window.on({
    load: () => {
        invokeMap(callbacks.load, call);

        delete callbacks.load;
    },
    resize: () => {
        invokeMap(callbacks.resize, call);
    }
});

export const $document = $(document);

$document
    .on('scroll', () => {
        if (scrollStopped) {
            invokeMap(callbacks.scroll.start, call);

            scrollStopped = false;
        }

        invokeMap(callbacks.scroll.every, call);
    })
    .ready(() => {
        invokeMap(callbacks.ready, call);

        delete callbacks.ready;
    });

export const $html = $(document.documentElement);

export const $body = $(document.body);

export const $main = $(`#${prefix('main')}`);
