'use strict';

// Elements
import './elements/_anchor';
import './elements/_equalize';
import './elements/_font-resize';
import './elements/_placeholder-label';
import './elements/_prevent';
import './elements/_tooltip';
import './elements/_show-password';
import './elements/_validate-password-conditions';
import './elements/_validate-pass-repass';

// Groups
import './groups/_search';
import './groups/_tabs';
import './groups/_editable-controls';

// Components
import './components/_carousel';
import './components/_query';
import './components/_progress-steps';
import './components/_provider-result';
import './components/_type-ahead';
import './components/_overlay';
import './components/_page-level-loader';
import './components/_button-level-loader';

// Modules
import './modules/_header';
import './modules/_footer';
import './modules/_chat';

// Pages
import './pages/_style-guide';
import './pages/_client-home';
import './pages/_client-update-info';
import './pages/_appointment-use-different-number';
import './pages/_hide-my-account-alerts';
import './pages/_add_edit_providers';
import './pages/_communication-preferences';
import './pages/_account_pov';

// TODO: Remove, not needed for production site
import './utilities/_user';
