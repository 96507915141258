'use strict';

import { prefix, $document } from '../utilities/_helpers';

$document.on({
    'show.bs.tab': (evt: Event) => {
        const $target = $(evt.target);
        const $parent = $target.parents(`.${prefix('provider-result')}`);
        const $collapse = $parent.find(`.${prefix('provider-maps')}`);
        const $tabs = $parent.find('[data-toggle="tab"]');

        // Fixes bug where previously activated tabs are no longer clickable
        $tabs.not($target).removeClass('active').attr('aria-expanded', 'false');

        $collapse.collapse('show');
    },
    'hide.bs.collapse': (evt: Event) => {
        const $target = $(evt.target);
        const $parent = $target.parents(`.${prefix('provider-result')}`);
        const $tabs = $parent.find('[data-toggle="tab"]');

        $tabs.removeClass('active').attr('aria-expanded', 'false');
    }
}, `.${prefix('provider-result')}`);

$document.on({
    'show.bs.tab': (evt: Event) => {
        const $target = $(evt.target);
        const $parent = $target.parents(`.${prefix('provider-results')}`);
        const $collapse = $parent.find(`.${prefix('provider-maps')}`);
        const $tabs = $parent.find('[data-toggle="tab"]');

        // Fixes bug where previously activated tabs are no longer clickable
        $tabs.not($target).removeClass('active').attr('aria-expanded', 'false');

        $collapse.collapse('show');
    },
    'hide.bs.collapse': (evt: Event) => {
        const $target = $(evt.target);
        const $parent = $target.parents(`.${prefix('provider-results')}`);
        const $tabs = $parent.find('[data-toggle="tab"]');

        $tabs.removeClass('active').attr('aria-expanded', 'false');
    }
}, `.${prefix('collapse')} .${prefix('result')}`);

$document.on('click', `.${prefix('provider-result')} input[type="radio"], .${prefix('provider-results')} input[type="radio"]`, (evt: Event) => {
    const $target = $(evt.target);
    const $parent = $target.parents(`.${prefix('provider-location')}`);

    $parent.find('[data-toggle]').trigger('click');
});
