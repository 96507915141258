'use strict';

import {$body} from '../utilities/_helpers'

$body.on('click', '[data-toggle="tab"]', (evt: Event) => {
    evt.stopPropagation();

    const $target = $(evt.target).closest('[data-toggle="tab"]');

    $target
        .tab('show')
        .parents('[data-toggle="collapse"]')
        .siblings('.collapse')
        .collapse('show');

    $('.collapse', $target.parents('.aon-collapse').siblings('.aon-collapse')).collapse('hide');

    return false;
});

$body.on('shown.bs.tab', '[data-toggle="tab"]', (evt: Event) => {
    const $target = $(evt.target);
    let target = $target.data('target');

    target = target.replace(/-controls/, '');

    $body.find(`[data-target="${target}"]`).tab('show');
});

$body.on('shown.bs.tab', '[data-toggle="tab"]', (evt: Event) => {
    const $target = $(evt.target);
    let target = $target.data('target');

    target = target.replace(/-(output|input|remove)/, '-controls-$1');

    $body.find(`[data-target="${target}"]`).tab('show');
});
