'use strict';

import { $document } from '../utilities/_helpers';

function openNav() {
    const $overlay = $('#myNav'),
          $iframe = $overlay.find('iframe');
    $overlay.css('width', '100%');
    $iframe.attr('src', $iframe.attr('src') + '&amp;autoplay=true');
}

function closeNav() {

    const $overlay = $('#myNav'),
          $iframe = $overlay.find('iframe');
    $iframe.attr('src', $iframe.attr('src').replace('&amp;autoplay=true', ''));
    $overlay.css('width', '0%');
}

$(document).on('click', '.aon-video-overlay', openNav);
$(document).on('click', '.close-video-overlay', closeNav);

