'use strict';

import { prefix, $window, $document } from './_helpers';

$document.on('click', '[data-toggle="user"]', () => {
    const $user = $('[data-js^="user"]');
    $user.toggleClass(prefix('hidden'));

    $window.trigger('resize');
});
