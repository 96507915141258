'use strict';

import { $document } from '../utilities/_helpers';

$document.on('click', '[data-js="response"]', (evt: Event) => {
    const $target = $(evt.target);

    if ($target.is('[data-js="response"]')) {
        $target.children().trigger('click');
    }
});
