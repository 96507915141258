'use strict';

import { onResize, $window, $document } from '../utilities/_helpers';

function pushFooterBelowViewPort(): void {
    const $footer = $('footer');
    $footer.css('margin-top', 0);

    const windowHeight = $window.height(),
        footerTop = $footer.offset().top,
        diff = windowHeight - footerTop;
    if (diff > 0) {
        $footer.css('margin-top', (windowHeight - footerTop) + 'px');
    }
}

if (!!$('footer').length) {
    pushFooterBelowViewPort();
    onResize(pushFooterBelowViewPort);
} else {
    $document.on('initializeFooter', pushFooterBelowViewPort);
}
