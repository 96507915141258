'use strict';

import { prefix, onResize, $window, $html, $document } from '../utilities/_helpers';

let $header: JQuery,
    $navUtil: JQuery,
    $chat: JQuery,
    chatEventsAttached = false;

function initializeChatVariables() {
    $header = $(`.${prefix('header')}`),
    $navUtil = $header.find('[data-js="nav-util"]'),
    $chat = $('[data-js="chat"]');
}

function adjust(): void {
    const width = $chat.is(':visible') ? $chat.outerWidth() : 0;

    $html.css({'margin-right': `${width}px`});

    $navUtil.css({right: `${width}px`});
}

function initializeChat() {
    initializeChatVariables();

    if (!!$chat.length && !chatEventsAttached) {
        $document.on('click', '[data-toggle="chat"]', () => {
            $chat.toggleClass(prefix('hidden'));

            $window.trigger('resize');
        });

        onResize(adjust);

        chatEventsAttached = true;
    }
}

initializeChat();
$document.on('initializeHeader', initializeChat);
