'use strict';

import { prefix, $window, $html, $document } from '../utilities/_helpers';

$document.on('click', `.${prefix('font-resize')}`, () => {
    $html.toggleClass(prefix('font-size-lg'));
    $html.one('transitionend otransitionend oTransitionEnd msTransitionEnd webkitTransitionEnd', function () {
        $window.trigger('resize');
    });
    return false;
});
